import * as React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import EssentialsLogo from '../images/items/essentials-thumbnail.png';
import MegapackLogo from '../images/items/megapack-thumbnail.png';
import IntroBg from '../icons/intro-bubbles.svg';
import IntroBgDark from '../icons/intro-bubbles-dark.svg';
import NewTabIcon from '../icons/buttons/pixfort-icon-open-new-tab-1.svg';
import EnvatoIcon from '../icons/pixfort-icon-envato-1.svg';

const ItemSupport = ({ data, location }) => {
    

    return (
        <Layout location={location} title="Terms of use">
            <Seo title='Support' />
            <div className=''>
                <main className='mx-auto mt-24 max-w-4xl px-4 sm:px-6 lg:px-8 md:mt-32'>
                    <div className='text-center text-gray-500 dark:text-gray-400 dark:prose-invert max-w-none'>
                        <h1 className='text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl md:text-6xl mb-4'>
                            <span className='block xl:inline'>Get support</span>
                        </h1>
                        <p className='block text-lg mb-4'>Select your product</p>
                        
                        
                        <div className='mx-auto flex flex-col md:flex-row '>

                            <div className="flex-1 justify-center m-4 md:m-8 bg-white shadow-2xl shadow-slate-300/50 dark:shadow-black/20 border border-gray-200 dark:border-white/10 dark:bg-gray-800 px-6 py-14 rounded-xl">
                                <a href='https://hub.pixfort.com/login' target='_blank'>
                                    <img className='inline-block m-0 pb-4' height='100' width='100' src={EssentialsLogo} />
                                </a>
                                <div className="">
                                    <h3 className="my-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">Essentials WordPress Theme</h3>
                                    <p className="mb-4 font-normal text-gray-400 dark:text-white/50">Get support and manage your licenses using your pixfort hub account.</p>
                                    <a href='https://hub.pixfort.com/login' target='_blank' className='inline-flex text-gray-700 hover:text-gray-900 dark:text-white dark:hover:text-gray-300 py-3 px-5 sm:mr-2 bg-white dark:bg-white/20 text-sm font-semibold rounded-md focus:outline-none transition ease-in-out hover:-translate-y-0.5 duration-200 border border-black/5 dark:border-white/10'>
                                        <NewTabIcon className='inline-block mr-1' />
                                        Go to pixfort hub
                                    </a>
                                </div>
                            </div>

                            <div className="flex-1 justify-center m-4 md:m-8 bg-white rounded-lg shadow-2xl shadow-slate-300/50 dark:shadow-black/20 border border-gray-200 dark:border-white/10 dark:bg-gray-800 px-6 py-14">
                                <a href='https://builder.pixfort.com/login' target='_blank'>
                                    <img className='inline-block m-0 pb-4' height='100' width='100' src={MegapackLogo} />
                                </a>
                                <div className="">
                                    <h3 className="my-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">MEGAPACK + pixfort builder</h3>
                                    <p className="mb-4 font-normal text-gray-400 dark:text-white/50">Contact us using your account on pixfort builder platform.</p>
                                    <a href='https://builder.pixfort.com/login' target='_blank' className='inline-flex text-gray-700 hover:text-gray-900 dark:text-white dark:hover:text-gray-300 py-3 px-5 sm:mr-2 bg-white dark:bg-white/20 text-sm font-semibold rounded-md focus:outline-none transition ease-in-out hover:-translate-y-0.5 duration-200 border border-black/5 dark:border-white/10'>
                                        <NewTabIcon className='inline-block mr-1' />
                                        Go to pixfort builder
                                    </a>
                                    
                                </div>
                            </div>

                        </div>

                        <p className='block text-lg mb-4 mt-8'>
                            <a href='http://1.envato.market/pixfort' target='_blank' className='inline-flex tracking-tight text-gray-500 hover:text-gray-700 dark:text-white dark:hover:text-gray-300 py-4 px-5 sm:mr-2 mb-2 bg-white dark:bg-white/10 text-sm font-semibold rounded-md focus:outline-none transition ease-in-out hover:-translate-y-0.5 duration-200 border border-black/5 dark:border-white/10'>
                                <EnvatoIcon className='inline-block mr-2 text-[#82b541]' />
                                For other products purchased on Envato Market
                            </a>
                        </p>
                        
                        


                        
                    </div>
                </main>
            </div>
        </Layout>
    );
};

export default ItemSupport;